.form {
  height: 100%;
  overflow-y: auto;
  /* border: 1px solid red; */
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 240px;
  /* border: 1px solid; */
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
  /* border: 1px solid;  */
}

.buttonGroup {
  display: flex;
  gap: 10px;
}
