.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

.intro h1 {
  font-weight: 700;
}

.brief {
  margin: 30px auto;
}

.contact {
  margin: 50px auto;
  flex-direction: column;
  /* border: 1px solid red; */
}
