.blockCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.width100 {
  width: 100%;
}

/* parent container mush be position:relative */
.stickyTop{
  position: sticky;
  top:0
}

.breakLine{
  white-space: pre-line;
}

.textCenter{
  text-align: center;
}
